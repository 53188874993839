import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import { CarrickfergusCastle, AntrimCoastAndGlens, CarnloughHarbour, CarrickARedeRopeBridge, GiantsCauseway, TheDarkHedges } from '../../components/Tours/Locations'
import { ContentWrapper, InfoWithImageRight, InfoWithImageLeft, SVGPattern } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const CarrickARedeTour = ({ location }) => {
  const {
    seoImg,
    bridge,
    giantscauseway,
    carrickarede2
  } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "rope-bridge.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      bridge: file(relativePath: { eq: "rope-bridge.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giantscauseway: file(relativePath: { eq: "giants-causeway-2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carrickarede2: file(relativePath: { eq: "carrick-a-rede-rope-bridge-2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Carrick-a-Rede Tour | Carrick-a-Rede Rope Bridge, Giant's Causeway, The Dark Hedges"
        description="Sway with the wind as you cross and take in the stunning views at the Carrick-a-Rede Rope Bridge, in an area of outstanding natural beauty."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={bridge.childImageSharp.fluid}
        imageAlt="Carrick-a-Rede Rope Bridge"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Carrick-a-Rede Rope Bridge
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Sway with the wind as you cross and take in the stunning views at the Carrick-a-Rede Rope Bridge, in an area of outstanding natural beauty.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>

        <SectionTitle
          title="Discover the Carrick-a-Rede Rope Bridge"
          text={<>Tour in comfort with cost effective sightseeing of the magnificent North Coast, including the famous Carrick-a-Rede Rope Bridge, in one of Chauffeur Me's <InlineLink to="/our-fleet">luxury fleet</InlineLink> of vehicles.</>}
        />

        <InfoWithImageRight
          image={carrickarede2.childImageSharp.fluid}
          imageAlt="Stunning views over the Carrick-a-Rede Rope Bridge"
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Dare to Cross the Rope Bridge?
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            Join us on our Carrick-a-Rede Rope Bridge Tour as we spend a day visiting some of Northern Ireland's most scenic attractions.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Visit the spectacular Antrim Coast and discover the key attractions it has to offer as our tour takes you on one of the world’s most spectacular drives!
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Brave the exhilarating experience by crossing the Carrick-a-Rede Rope Bridge high above the water and open to the elements – feel the creak of wooden slats in the rushing wind. Hear the waves swirling in beneath your feet. And breathe in the salty spray rising from below.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Enjoy it all in the comfort of our luxury fleet of vehicles as you revel in the knowledge of our friendly and experienced drivers who are there for you every step of the way.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={giantscauseway.childImageSharp.fluid}
          imageAlt="Sunset over the famous basalt columns at the Giant's Causeway."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Explore Ireland with Chauffeur Me
          </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            Visit six prime locations on Ireland’s northern coastline as we make this a tour that is not to be missed.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            En route to the Carrick-a-Rede we visit Carrickfergus Castle, travel through the marvellous Antrim Coast and Glens Area of Natural Beauty and take a walk by the small harbor in the picturesque village of Carnlough.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Our driver will stop you off for lunch in the coastal town of Ballycastle before our main attraction of the day – Carrick-a-Rede Rope Bridge. Suspended almost 100ft above sea level, the rope bridge was first erected by salmon fisherman nearly 300 years ago.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            No tour of Ireland's North Coast is complete without stopping off at the Giant's Causeway, before a final photo stop at the Dark Hedges, made popular by hit TV series Game of Thrones, on our homeward journey.
          </p>
        </InfoWithImageLeft>
      </ContentWrapper>

      <OffsetGridWrapper title="Carrick-a-Rede Tour Locations">
        <CarrickfergusCastle />
        <AntrimCoastAndGlens />
        <CarnloughHarbour />
        <CarrickARedeRopeBridge />
        <GiantsCauseway />
        <TheDarkHedges />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default CarrickARedeTour